import React, { useState } from "react";
import AsuntoModal from "../../containers/Asunto/Modal";
import { IconEye, IconPencil, IconTrash } from '@tabler/icons-react';
import { useSelector } from "react-redux";

function AsuntoCardHorizontal({ data, onDelete, onView }) {
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const handleEdit = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <tr className="bg-white dark:bg-gray-800">
        <td className="px-6 py-3 text-sm font-medium text-gray-900 dark:text-white whitespace-nowrap text-center align-middle">
          {data.name}
        </td>
        <td className="px-6 py-3 text-sm text-gray-600 dark:text-gray-400 whitespace-nowrap text-center align-middle">
          {data.active ? "Activo" : "Inactivo"}
        </td>
        <td className="px-6 py-3 text-sm text-gray-600 dark:text-gray-400 whitespace-nowrap text-center align-middle">
          {new Date(data.create_at).toLocaleDateString()}
        </td>
        <td className="px-6 py-3 text-sm text-gray-600 dark:text-gray-400 whitespace-nowrap text-center align-middle">
          {
            data.user ? data.user.first_name + " " + data.user.last_name : ""
          }
        </td>
        <td className="px-6 py-3 text-sm text-center align-middle">
         {
          user && user.role === "Comunicador" && (
            <button
            onClick={handleEdit}
            className="text-yellow-500 hover:text-yellow-700 dark:text-yellow-300 dark:hover:text-yellow-500 mr-3"
          >
            <IconPencil />
          </button>
          )
         }

        </td>
      </tr>
      {showModal && (
        <AsuntoModal
          show={showModal}
          onClose={closeModal}
          asuntoData={data}
          refreshAsuntos={() => window.location.reload()}  // Puedes reemplazar esto por una mejor estrategia de actualización
        />
      )}
    </>
  );
}

export default AsuntoCardHorizontal;
