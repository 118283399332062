import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Error404 from "../../containers/error/Error404";
import Login from "../../containers/auth/Login";
import ForgotPassword from "../../containers/auth/ForgotPassword";
import Dashboard from "../../containers/Dashboard";
import Register from "../../containers/auth/Register";
import TokenVerify from "../../containers/auth/Token";
import PasswordConfirm from "../../containers/auth/PasswordConfirm";
import PrivateRoutes from "./PrivateRoutes";
import Asistencia from "../../containers/Asistencia/Asistencia";
import AsistenciaForm from "../../containers/Asistencia/AsistenciaForm";
import Asunto from "../../containers/Asunto/Asunto";
import AsuntoSearch from "../../containers/Asunto/AsuntoSearch";
import AsistenciaSearch from "../../containers/Asistencia/AsistenciaSearch";
import AsistenciaReport from "../../containers/Asistencia/AsistenciaReport";
import CaracterizacionForm from "../../containers/Caracterizacion/CaracterizacionForm";

function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="*" element={<Error404 />} />
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/password/reset/confirm/:uid/:token"element={<PasswordConfirm />} />
        {/* <Route path="/register" element={<Register />} />
        <Route path="/token/verify" element={<TokenVerify />} /> */}

        <Route path="/dashboard" element={
          <PrivateRoutes>
            <Dashboard />
          </PrivateRoutes>
        } />

        <Route path="/asistencia" element={
          <PrivateRoutes>
            <Asistencia />
          </PrivateRoutes>
        } />

        <Route path="/asistencia/form"element={
          <PrivateRoutes>
            <AsistenciaForm />
          </PrivateRoutes>
        } />
        <Route path="/asistencia/search/:search_term"element={
          <PrivateRoutes>
            <AsistenciaSearch />
          </PrivateRoutes>
        } />
        <Route path="/asistencia/report/:uid"element={
          <PrivateRoutes>
            <AsistenciaReport />
          </PrivateRoutes>
        } />
      {/* Asuntos */}
        <Route path="/asuntos"element={
          <PrivateRoutes>
            <Asunto />
          </PrivateRoutes>
        } />
        <Route path="/asuntos/search/:search_term"element={
          <PrivateRoutes>
            <AsuntoSearch />
          </PrivateRoutes>
        } />
        {/* Caracterizacion */}

        <Route path="/caracterizacion" element={
          <PrivateRoutes>
            <CaracterizacionForm />
          </PrivateRoutes>
        } />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
