import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { IconHome, IconChevronDown, IconChecklist } from "@tabler/icons-react";

function Sidebar({ selectedItem, handleSidebarClick, darkTheme }) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState({});
  const user = useSelector((state) => state.auth.user);

  const clientNavigation = [
    {
      name: "Dashboard",
      href: "/dashboard",
      icon: IconHome,
      roles: ["Comunicador", "User"], // Both roles can see this
    },
    {
      name: "Caracterización",
      href: "/caracterizacion",
      icon: IconChecklist,
      roles: ["Comunicador"], 
    },
    {
      name: "Asistencia",
      href: "/asistencia",
      icon: IconChecklist,
      roles: ["Comunicador","User"], 
    },
    {
      name: "Asuntos",
      href: "/asuntos",
      icon: IconHome,
      roles: ["Comunicador"],
    },
  ];

  useEffect(() => {
    const pathname = location.pathname;
    const newIsOpen = {};

    const currentNavigation = clientNavigation;

    currentNavigation.forEach((item) => {
      if (item.children) {
        const hasSelectedChild = item.children.some(
          (child) =>
            child.href === pathname ||
            (child.children &&
              child.children.some((grandchild) => grandchild.href === pathname))
        );
        if (hasSelectedChild) {
          newIsOpen[item.name] = true;
        }
        item.children.forEach((child) => {
          if (child.children) {
            const hasSelectedGrandChild = child.children.some(
              (grandchild) => grandchild.href === pathname
            );
            if (hasSelectedGrandChild) {
              newIsOpen[child.name] = true;
            }
          }
        });
      }
    });

    setIsOpen(newIsOpen);
  }, [location]);

  const handleDropdownClick = (name) => {
    setIsOpen((prevIsOpen) => ({ ...prevIsOpen, [name]: !prevIsOpen[name] }));
  };

  const renderNavItem = (item, level = 0) => {
    // Check if the user's role is allowed to see the item
    if (!user || !item.roles.includes(user.role)) {
      return null; // Don't render this item if the user doesn't have permission
    }

    if (item.children) {
      return (
        <li key={item.name} className="mt-0.5 w-full">
          <div
            className={`py-2.7 text-sm ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap rounded-lg px-4 font-poppins-semibold transition-colors cursor-pointer ${
              selectedItem === item.name
                ? "font-poppins-semibold text-white"
                : darkTheme
                ? "text-gray-300"
                : "text-gray-700"
            }`}
            onClick={() => handleDropdownClick(item.name)}
          >
            <div
              className={`mr-2 flex h-10 w-10 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-1.5 ${
                selectedItem === item.name
                  ? ""
                  : darkTheme
                  ? "bg-gray-700"
                  : "bg-gray-200"
              } transition-colors duration-200`}
            >
              <item.icon
                className={`text-${
                  selectedItem === item.name
                    ? "blue-500"
                    : darkTheme
                    ? "white"
                    : "black"
                }`}
              />
            </div>
            <span className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft">
              {item.name}
            </span>
            <IconChevronDown
              className={`h-5 w-5 text-gray-600 transition-transform ${
                isOpen[item.name] ? "rotate-180 transform" : ""
              }`}
            />
          </div>
          {isOpen[item.name] && (
            <ul className="ml-4 mt-2 flex flex-col gap-2">
              {item.children.map((child) => renderNavItem(child, level + 1))}
            </ul>
          )}
        </li>
      );
    } else {
      return (
        <li key={item.name} className="mt-0.5 w-full">
          <NavLink
            to={item.href}
            className={`py-2.7 text-sm ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap rounded-lg px-4 font-poppins-semibold transition-colors ${
              selectedItem === item.name
                ? "bg-gradient-to-tl from-[#00A1FF] to-blue-700 font-poppins-semibold text-white"
                : darkTheme
                ? "text-gray-300"
                : "text-gray-700"
            }`}
            onClick={() => handleSidebarClick(item.name)}
          >
            <div
              className={`mr-2 flex h-10 w-10 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-1.5 ${
                selectedItem === item.name
                  ? "bg-gradient-to-tl from-[#00A1FF] to-blue-700"
                  : darkTheme
                  ? "bg-[#00A1FF]"
                  : "bg-gray-200"
              } transition-colors duration-200`}
            >
              <item.icon
                className={`text-${
                  selectedItem === item.name
                    ? "white"
                    : darkTheme
                    ? "white"
                    : "black"
                }`}
              />
            </div>
            <span className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft">
              {item.name}
            </span>
          </NavLink>
        </li>
      );
    }
  };

  return (
    <ul className="flex flex-col pl-0 mb-0">
      {clientNavigation.map((item) => renderNavItem(item))}
    </ul>
  );
}

export default Sidebar;
