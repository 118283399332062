import React, { Fragment, useEffect, useRef, useState } from "react";
import DashboardLayout from "../../hocs/layout/DashboardLayout";
import SignatureCanvas from "react-signature-canvas";
import axios from "axios";
import { Combobox, Transition } from "@headlessui/react";
import Toast from "../../components/Alert";
import { useNavigate } from "react-router-dom";
import { IconChevronDown } from "@tabler/icons-react";

function AsistenciaForm({}) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    autorizadatos: "",
    fechaencuentro: "",
    asunto: "",
    tipodocumento: "",
    numeroidentificacion: "",
    edad: "",
    nombrecompleto: "",
    zona: "",
    barrio: "",
    direccion: "",
    municipio: "",
    telefono1: "",
    telefono2: "",
    genero: "",
    firma: "",
    email: "",
    escolaridad: "",
    grupoetnico: "",
    grupopoblacional: "",
    regimensalud: "",
    responsable: "",
    fotopersona: "",
    fotoidentificacion: "",
  });
  const [asuntoList, setAsuntoList] = useState([]);
  const [selectedAsunto, setSelectedAsunto] = useState(null);
  const [query, setQuery] = useState("");

  useEffect(() => {
    const fetchAsuntoList = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/asunto/minimal/`,
          config
        );
        setAsuntoList(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAsuntoList();
  }, []);

  const filteredAsuntoList =
    query === ""
      ? asuntoList
      : asuntoList.filter((asunto) =>
          asunto.name.toLowerCase().includes(query.toLowerCase())
        );

  const handleSelectAsunto = (asunto) => {
    if (asunto) {
      setSelectedAsunto(asunto);
      setFormData({ ...formData, asunto: asunto.id }); // Almacena el ID en formData
    } else {
      setSelectedAsunto(null);
      setFormData({ ...formData, asunto: "" }); // Limpia el campo asunto si no hay selección
    }
  };

  const [currentStep, setCurrentStep] = useState(1);
  const signatureCanvas = useRef(null);
  const [imageData, setImageData] = useState("");
  const [error, setError] = useState(false);
  const [personPhoto, setPersonPhoto] = useState("");
  const [identificationPhoto, setIdentificationPhoto] = useState("");
  const [previewImage, setPreviewImage] = useState();

  const saveSignature = () => {
    const signature = signatureCanvas.current.toDataURL();
    setImageData(signature);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const personPhotoSelectedHandler = (e) => {
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      setPreviewImage(reader.result);
    };
    setPersonPhoto(file);
  };

  const identificationPhotoSelectedHandler = (e) => {
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      setPreviewImage(reader.result);
    };
    setIdentificationPhoto(file);
  };

  const validateStep = () => {
    switch (currentStep) {
      case 1:
        return formData.autorizadatos !== "";
      case 2:
        return formData.fechaencuentro !== "";
      case 3:
        return (
          formData.asunto !== "" &&
          formData.asunto.length <= 150 && // Validar longitud del asunto
          formData.responsable !== "" &&
          formData.nombrecompleto !== "" &&
          formData.tipodocumento !== "" &&
          formData.numeroidentificacion !== "" &&
          formData.edad !== ""
        );
      case 4:
        return (
          formData.municipio !== "" &&
          formData.zona !== "" &&
          formData.barrio !== "" &&
          formData.direccion !== ""
        );
      case 5:
        return formData.telefono1 !== "" && formData.email !== "";
      case 6:
        return (
          formData.genero !== "" &&
          formData.escolaridad !== "" &&
          formData.grupoetnico !== "" &&
          formData.grupopoblacional !== "" &&
          formData.regimensalud !== ""
        );
      case 7:
        return imageData !== "";

      default:
        return false;
    }
  };

  const transformFormData = () => {
    const transformedData = {};
    for (const [key, value] of Object.entries(formData)) {
      const newKey = key.toLowerCase();
      transformedData[newKey] = value;
    }

    // Convertir 'autorizadatos' en un booleano
    transformedData.autorizadatos = formData.autorizadatos === "Si";

    // Asegurar el formato de la fecha
    transformedData.fechaencuentro = formData.fechaencuentro.split("T")[0];

    return transformedData;
  };

  const nextStep = () => {
    if (currentStep === 1 && formData.autorizadatos === "No") {
      setError(true);
      return;
    }

    if (validateStep()) {
      setError(false);
      setCurrentStep(currentStep + 1);
    } else {
      setError(true);
    }
  };

  const prevStep = () => {
    setError(false);
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateStep()) {
      saveSignature();

      let transformedFormData = transformFormData();

      // Decodificar la imagen base64
      const base64ImageContent = imageData.replace(
        /^data:image\/(png|jpeg);base64,/,
        ""
      );
      const byteCharacters = atob(base64ImageContent);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" });

      // Crear un archivo con el blob
      const file = new File([blob], "firma.png", { type: "image/png" });

      // Crear form data para enviar incluyendo el archivo
      const formDataToSend = new FormData();
      for (const [key, value] of Object.entries(transformedFormData)) {
        formDataToSend.append(key, value);
      }
      formDataToSend.append("firma", file);

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/asistencia/crear/`,
          formDataToSend,
          config
        );
        if (response.status === 201) {
          Toast({
            tipo: "success",
            mensaje: "¡Asistencia registrada correctamente!",
          });
          navigate("/asistencia");
        }
      } catch (error) {
        console.error(error);
        Toast({
          tipo: "error",
          mensaje: "¡Hubo un error al registrar la asistencia!",
        });
      }
    } else {
      setError(true);
    }
  };

  return (
    <DashboardLayout>
      <div className="min-h-screen flex flex-col items-center">
        <h1 className="text-2xl font-bold mb-4">
          {`Paso ${currentStep} de 7`}
        </h1>
        <form className="w-full max-w-lg" onSubmit={handleSubmit}>
          {currentStep === 1 && (
            <div>
              <p>
                Bienvenido al formulario de seguimiento para la población
                beneficiaria del proyecto Mujeres Perseverantes, Seguras y
                Productivas. Agradecemos de antemano su colaboración en
                completar los datos requeridos.
              </p>
              <p className="mt-2 font-bold">
                ¿Autoriza usted el tratamiento de sus datos personales?
              </p>
              <div className="mt-2 flex items-center gap-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="autorizadatos"
                    value="Si"
                    checked={formData.autorizadatos === "Si"}
                    onChange={handleChange}
                  />
                  <span className="ml-2">Sí</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="autorizadatos"
                    value="No"
                    checked={formData.autorizadatos === "No"}
                    onChange={handleChange}
                  />
                  <span className="ml-2">No</span>
                </label>
              </div>
              {error && (
                <p className="text-red-500 text-sm mt-2">
                  Por favor, selecciona "Sí" o "No" para autorizar el
                  tratamiento de tus datos.
                </p>
              )}
            </div>
          )}

          {currentStep === 2 && (
            <div>
              <label className="block">
                Fecha y hora del encuentro:
                <input
                  type="datetime-local"
                  name="fechaencuentro"
                  value={formData.fechaencuentro}
                  onChange={handleChange}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                />
              </label>
            </div>
          )}

          {currentStep === 3 && (
            <div>
              <div className="mt-4">
                <label className="block">
                  Asunto:
                  <Combobox
                    value={selectedAsunto}
                    onChange={handleSelectAsunto}
                  >
                    <div className="relative mt-1">
                      <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-teal-300 focus-visible:ring-offset-2 sm:text-sm">
                        <Combobox.Input
                          className="input input-bordered input-md w-full"
                          displayValue={(asunto) => (asunto ? asunto.name : "")}
                          onChange={(event) => setQuery(event.target.value)}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                          <IconChevronDown />
                        </Combobox.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery("")}
                      >
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {filteredAsuntoList.length === 0 && query !== "" ? (
                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                              No se encontraron resultados.
                            </div>
                          ) : (
                            filteredAsuntoList.map((asunto) => (
                              <Combobox.Option
                                key={asunto.id}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                    active
                                      ? "text-white bg-teal-600"
                                      : "text-gray-900"
                                  }`
                                }
                                value={asunto}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={`block truncate ${
                                        selected ? "font-medium" : "font-normal"
                                      }`}
                                    >
                                      {asunto.name}
                                    </span>
                                    {selected ? (
                                      <span
                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                          active
                                            ? "text-white"
                                            : "text-teal-600"
                                        }`}
                                      >
                                        {/* <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        /> */}
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Combobox.Option>
                            ))
                          )}
                        </Combobox.Options>
                      </Transition>
                    </div>
                  </Combobox>
                </label>
              </div>
              <label className="block mt-4">
                Responsable:
                <select
                  name="responsable"
                  value={formData.responsable}
                  onChange={handleChange}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pl-3 pr-8 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                >
                  <option value="">Seleccione una opción</option>
                  <option value="Fundación Arduvi">Fundación Arduvi</option>
                </select>
              </label>
              <label className="block mt-4">
                Nombres y apellidos:
                <input
                  type="text"
                  name="nombrecompleto"
                  value={formData.nombrecompleto}
                  onChange={(e) => {
                    const re = /^[a-zA-Z\s]*$/; // Solo letras y espacios
                    if (re.test(e.target.value) || e.target.value === "") {
                      handleChange(e); // Solo actualiza el valor si es válido
                    }
                  }}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                />
              </label>

              <label className="block mt-4">
                Tipo de documento de identidad:
                <select
                  name="tipodocumento"
                  value={formData.tipodocumento}
                  onChange={handleChange}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pl-3 pr-8 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                >
                  <option value="">Seleccione una opción</option>
                  <option value="CC">Cédula de ciudadanía</option>
                  <option value="CE">Cédula de extranjería</option>
                  <option value="PA">Pasaporte</option>
                  <option value="TI">Tarjeta de identidad</option>
                  <option value="RC">Registro civil</option>
                  <option value="PE">Permiso especial de permanencia</option>
                  <option value="PT">Permiso de proteción temporal</option>
                </select>
              </label>

              <label className="block mt-4">
                Número de identificación:
                <input
                  type="text"
                  name="numeroidentificacion"
                  value={formData.numeroidentificacion}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Validar que sea un número positivo y entre 7 y 11 caracteres
                    if (/^\d{0,11}$/.test(value)) {
                      handleChange(e); // Actualizar solo si cumple la longitud
                    }
                  }}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                />
                {formData.numeroidentificacion.length > 0 &&
                  (formData.numeroidentificacion.length < 7 ||
                    formData.numeroidentificacion.length > 11) && (
                    <span className="text-red-500 text-xs">
                      El número de identificación debe tener entre 7 y 11
                      dígitos.
                    </span>
                  )}
              </label>

              <label className="block mt-4">
                Edad:
                <input
                  type="number"
                  name="edad"
                  value={formData.edad}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    // Validar que sea un número positivo y menor o igual a 100
                    if (value >= 0 && value <= 100) {
                      handleChange(e); // Actualizar solo si es una edad válida
                    }
                  }}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                />
                {formData.edad &&
                  (formData.edad < 0 || formData.edad > 100) && (
                    <span className="text-red-500 text-xs">
                      La edad debe estar entre 0 y 100 años.
                    </span>
                  )}
              </label>
            </div>
          )}

          {currentStep === 4 && (
            <div>
              <label className="block mt-4">
                Municipio:
                <select
                  name="municipio"
                  value={formData.municipio}
                  onChange={handleChange}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pl-3 pr-8 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                >
                  <option value="">Seleccione un municipio</option>
                  <option value="CU">Cúcuta</option>
                  <option value="LP">Los Patios</option>
                  <option value="VR">Villa del Rosario</option>
                  <option value="EZ">El Zulia</option>
                </select>
              </label>

              <label className="block mt-4">
                Zona donde reside:
                <select
                  name="zona"
                  value={formData.zona}
                  onChange={handleChange}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pl-3 pr-8 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                >
                  <option value="">Seleccione una zona</option>
                  <option value="U">Urbana</option>
                  <option value="R">Rural</option>
                </select>
              </label>

              <label className="block mt-4">
                Indique el barrio o corregimiento donde reside:
                <input
                  type="text"
                  name="barrio"
                  value={formData.barrio}
                  onChange={handleChange}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                />
              </label>

              <label className="block mt-4">
                Dirección de residencia:
                <input
                  type="text"
                  name="direccion"
                  value={formData.direccion}
                  onChange={handleChange}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                />
              </label>
            </div>
          )}

          {currentStep === 5 && (
            <div>
              <label className="block">
                Teléfono 1:
                <input
                  type="text"
                  name="telefono1"
                  value={formData.telefono1}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Validar que comience con 3 y que tenga 10 dígitos
                    if (/^3\d{0,9}$/.test(value)) {
                      handleChange(e); // Actualiza el campo solo si cumple con las condiciones
                    }
                  }}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                />
                {formData.telefono1.length > 0 &&
                  formData.telefono1.length !== 10 && (
                    <span className="text-red-500 text-xs">
                      El número de teléfono debe tener 10 dígitos y empezar por
                      3.
                    </span>
                  )}
              </label>

              <label className="block mt-4">
                Teléfono 2 (Contacto de emergencia):
                <input
                  type="text"
                  name="telefono2"
                  value={formData.telefono2}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Validar si se ingresa un número, debe comenzar con 3 y tener 10 dígitos
                    if (value === "" || /^3\d{0,9}$/.test(value)) {
                      handleChange(e); // Actualiza solo si cumple la condición o si está vacío
                    }
                  }}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                />
                {formData.telefono2.length > 0 &&
                  formData.telefono2.length !== 10 && (
                    <span className="text-red-500 text-xs">
                      El número de teléfono debe tener 10 dígitos y empezar por
                      3.
                    </span>
                  )}
              </label>

              <label className="block mt-4">
                Correo electrónico:
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Validar el formato del email
                    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                      handleChange(e); // Actualiza solo si es un formato válido de email
                    } else {
                      handleChange({ target: { name: "email", value: "" } }); // Limpia el campo si no es válido
                    }
                  }}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                />
                {formData.email.length > 0 &&
                  !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email) && (
                    <span className="text-red-500 text-xs">
                      El formato del correo electrónico no es válido.
                    </span>
                  )}
              </label>
            </div>
          )}

          {currentStep === 6 && (
            <div>
              <label className="block mt-4">
                Género:
                <select
                  name="genero"
                  value={formData.genero}
                  onChange={handleChange}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pl-3 pr-8 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                >
                  <option value="">Seleccione...</option>
                  <option value="F">Femenino</option>
                  <option value="M">Masculino</option>
                  <option value="O">Otro</option>
                </select>
              </label>

              <label className="block mt-4">
                Escolaridad:
                <select
                  name="escolaridad"
                  value={formData.escolaridad}
                  onChange={handleChange}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pl-3 pr-8 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                >
                  <option value="">Seleccione una opción</option>
                  <option value="Primaria">Primaria</option>
                  <option value="Secundaria">Secundaria</option>
                  <option value="Técnico">Técnico</option>
                  <option value="Tecnólogo">Tecnólogo</option>
                  <option value="Pregrado">Pregrado</option>
                  <option value="Postgrado">Postgrado</option>
                  <option value="Ninguna">Ninguna</option>
                  <option value="Otro">Otro</option>
                </select>
              </label>

              <label className="block mt-4">
                Grupo Étnico:
                <select
                  name="grupoetnico"
                  value={formData.grupoetnico}
                  onChange={handleChange}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pl-3 pr-8 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                >
                  <option value="">Seleccione un grupo étnico</option>
                  <option value="Afrocolombiano">Afrocolombiano</option>
                  <option value="Comunidad negra">Comunidad negra</option>
                  <option value="Palenquero">Palenquero</option>
                  <option value="Rom/Gitano">Rom/Gitano</option>
                  <option value="Indígena">Indígena</option>
                  <option value="Rizal del archipielago de San Andrés y Providencia">
                    Rizal del archipielago de San Andrés y Providencia
                  </option>
                  <option value="No se auto reconoce en ninguno de los anteriores">
                    No se auto reconoce en ninguno de los anteriores
                  </option>
                </select>
              </label>

              <label className="block mt-4">
                Grupo poblacional:
                <select
                  name="grupopoblacional"
                  value={formData.grupopoblacional}
                  onChange={handleChange}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pl-3 pr-8 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                >
                  <option value="">Seleccione un grupo poblacional</option>
                  <option value="Migrante">Migrante</option>
                  <option value="Madre cabeza de hogar">
                    Madre cabeza de hogar
                  </option>
                  <option value="Campesino">Campesino</option>
                  <option value="Víctima de violencia basada en género">
                    Víctima de violencia basada en género
                  </option>
                  <option value="Retornado">Retornado</option>
                  <option value="Víctima de conflicto armado">
                    Víctima de conflicto armado
                  </option>
                  <option value="Excombatiente">Excombatiente</option>
                  <option value="Discapacidad">Discapacidad</option>
                  <option value="Mujer cuidadora">Mujer cuidadora</option>
                  <option value="Líder social">Líder social</option>
                  <option value="OSIGD">OSIGD</option>
                  <option value="No aplica">No aplica</option>
                  <option value="Vulnerable">Vulnerable</option>
                </select>
              </label>

              <label className="block mt-4">
                Régimen de salud:
                <select
                  name="regimensalud"
                  value={formData.regimensalud}
                  onChange={handleChange}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pl-3 pr-8 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                >
                  <option value="">Seleccione un regimen de salud</option>
                  <option value="Contributivo">Contributivo</option>
                  <option value="Subsidiado">Subsidiado</option>
                  <option value="Sin afiliación">Sin afiliación</option>
                </select>
              </label>
            </div>
          )}

          {currentStep === 7 && (
            <div>
              <div className="mb-4">
                <div className="">
                  Firma:
                  <div className="flex justify-center w-full">
                    <SignatureCanvas
                      ref={signatureCanvas}
                      penColor="black"
                      canvasProps={{
                        width: 320,
                        height: 200,
                        className:
                          "bg-white border rounded justify-center items-center my-2",
                      }}
                      onEnd={saveSignature} // Captura la firma en cuanto se termine de escribir
                    />
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => signatureCanvas.current.clear()}
                  className="mt-2 px-4 py-2 text-sm font-semibold text-blue-500 border border-blue-500 rounded-lg shadow-md hover:scale-105 transform transition ease-in-out duration-150 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-[#00A1FF]"
                >
                  Limpiar firma
                </button>
              </div>
              <div className="mb-4">
                <label htmlFor="photo" className="label">
                  <span className="label-text text-gray-600 dark:text-gray-200">
                    Foto de la persona
                  </span>
                </label>
                <input
                  name="photo"
                  type="file"
                  accept="image/*"
                  onChange={(e) => personPhotoSelectedHandler(e)}
                  className="file-input file-input-bordered w-full"
                />
              </div>
              <div>
                <label htmlFor="photo_id" className="label">
                  <span className="label-text text-gray-600 dark:text-gray-200">
                    Foto de la identificación
                  </span>
                </label>
                <input
                  name="photo_id"
                  type="file"
                  accept="image/*"
                  onChange={(e) => identificationPhotoSelectedHandler(e)}
                  className="file-input file-input-bordered w-full"
                />
              </div>
            </div>
          )}

          {error && (
            <p className="text-red-500 text-sm mt-4">
              Por favor, completa todos los campos antes de continuar.
            </p>
          )}

          <div className="flex justify-between mt-6">
            {currentStep > 1 && (
              <button
                type="button"
                onClick={prevStep}
                className="px-5 py-2 text-sm font-semibold text-white bg-gradient-to-r from-[#00A1FF] to-blue-600 rounded-lg shadow-md hover:scale-105 transform transition ease-in-out duration-150 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00A1FF]"
              >
                Anterior
              </button>
            )}
            {currentStep < 7 ? (
              <button
                type="button"
                onClick={nextStep}
                className="px-5 py-2 text-sm font-semibold text-white bg-gradient-to-r from-[#00A1FF] to-blue-600 rounded-lg shadow-md hover:scale-105 transform transition ease-in-out duration-150 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00A1FF]"
              >
                Siguiente
              </button>
            ) : (
              <button
                type="submit"
                className="px-5 py-2 text-sm font-semibold text-white bg-gradient-to-r from-[#00A1FF] to-blue-600 rounded-lg shadow-md hover:scale-105 transform transition ease-in-out duration-150 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00A1FF]"
              >
                Enviar
              </button>
            )}
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
}

export default AsistenciaForm;
