import React, { useEffect, useState } from "react";
import DashboardLayout from "../hocs/layout/DashboardLayout";

function Dashboard() {


  return (
    <DashboardLayout>
      <main className="min-h-screen dark:bg-gray-900 text-gray-900 dark:text-white">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div className="bg-blue-200 rounded-lg p-2 flex gap-x-2">
            <div className="w-3/12 flex items-center">
              <svg  xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="#4b5563" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-map-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 18.5l-3 -1.5l-6 3v-13l6 -3l6 3l6 -3v7.5" /><path d="M9 4v13" /><path d="M15 7v5.5" /><path d="M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z" /><path d="M19 18v.01" /></svg>
            </div>
            <div className="w-9/12">
              <p className="font-medium text-gray-600">Comunidades visitadas</p>
              <p className="font-bold text-gray-600 mb-0 text-xl">6</p>
            </div>
          </div>
          <div className="bg-blue-200 rounded-lg p-2 flex gap-x-2">
            <div className="w-3/12 flex items-center">
              <svg  xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="#4b5563" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-calendar-month"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z" /><path d="M16 3v4" /><path d="M8 3v4" /><path d="M4 11h16" /><path d="M7 14h.013" /><path d="M10.01 14h.005" /><path d="M13.01 14h.005" /><path d="M16.015 14h.005" /><path d="M13.015 17h.005" /><path d="M7.01 17h.005" /><path d="M10.01 17h.005" /></svg>
            </div>
            <div className="w-9/12">
              <p className="font-medium text-gray-600 mb-1">Registros del mes</p>
              <p className="font-bold text-gray-600 mb-0 text-xl">24</p>
            </div>
          </div>
          <div className="bg-blue-200 rounded-lg p-2 flex gap-x-2">
            <div className="w-3/12 flex items-center">
              <svg  xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none"  stroke="#4b5563" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-report-analytics"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" /><path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" /><path d="M9 17v-5" /><path d="M12 17v-1" /><path d="M15 17v-3" /></svg>
            </div>
            <div className="w-9/12">
              <p className="font-medium text-gray-600 mb-1">Total de registros</p>
              <p className="font-bold text-gray-600 mb-0 text-xl">236</p>
            </div>
          </div>
        </div>
      </main>
    </DashboardLayout>
  );
}

export default Dashboard;
