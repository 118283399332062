import { BrowserRouter as Router } from "react-router-dom";
import store from "./store";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import AnimatedRoutes from "./hocs/route/Routes";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Fundacion Arduvi App</title>
      </Helmet>
      <Provider store={store}>
        <Router>
          <AnimatedRoutes />
          <ToastContainer />
        </Router>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
