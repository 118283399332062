import React, { useState } from "react";
import DashboardLayout from "../../hocs/layout/DashboardLayout";
import Toast from "../../components/Alert"; // Asumiendo que tienes una función de alerta personalizada

function CaracterizacionForm() {
  const [formData, setFormData] = useState({
    // paso 1
    autorizadatos: "",
    // paso 2
    nombres: "",
    apellidos: "",
    nacionalidad: "",
    lugar_origen: "",
    tipo_documento: "",
    numero_documento: "",
    genero: "",
    orientacion_identidad: "",
    fecha_nacimiento: "",
    estado_civil: "",
    telefono: "",
    correo: "",
    departamento: "",
    municipio: "",
    barrio: "",
    comuna: "",
    estrato: "",
    direccion: "",
    perfil_migratiorio: "",
    grupo_etnico: "",
    vive_actualmente: "",
    victima_conflicto: "",
    registro_unico_victimas: "",
    numero_registro_unico_victimas: "",
    discapacidad: "",
    tipo_discapacidad: "",
    personas_a_cargo_asistencia: "",
    tipo_persona_a_cargo_asistencia: "",
    // paso 3
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState(false);

  // Función para manejar los cambios en los inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Validación de cada paso
  const validateStep = () => {
    switch (currentStep) {
      case 1:
        // Validar que se haya seleccionado "Sí" en la autorización de datos
        return formData.autorizadatos === "Si";
      case 2:
        return (
          formData.nombres !== "" &&
          formData.apellidos !== "" &&
          formData.nacionalidad !== "" &&
          formData.lugar_origen !== "" &&
          formData.tipo_documento !== "" &&
          formData.numero_documento !== "" &&
          formData.genero !== "" &&
          formData.orientacion_identidad !== "" &&
          formData.fecha_nacimiento !== "" &&
          formData.estado_civil !== "" &&
          formData.telefono !== "" &&
          formData.correo !== "" &&
          formData.departamento !== "" &&
          formData.municipio !== "" &&
          formData.barrio !== "" &&
          formData.comuna !== "" &&
          formData.estrato !== "" &&
          formData.direccion !== "" &&
          formData.perfil_migratiorio !== "" &&
          formData.grupo_etnico !== "" &&
          formData.vive_actualmente !== "" &&
          formData.victima_conflicto !== "" &&
          formData.registro_unico_victimas !== "" &&
          formData.numero_registro_unico_victimas !== "" &&
          formData.discapacidad !== "" &&
          formData.tipo_discapacidad !== "" &&
          formData.personas_a_cargo_asistencia !== "" &&
          formData.tipo_persona_a_cargo_asistencia !== ""
        );
      default:
        return false;
    }
  };

  const nextStep = () => {
    if (validateStep()) {
      setError(false);
      setCurrentStep(currentStep + 1);
    } else {
      setError(true);
    }
  };

  const prevStep = () => {
    setError(false);
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateStep()) {
      try {
        // Aquí puedes hacer la lógica para enviar los datos
        Toast({
          tipo: "success",
          mensaje: "¡Formulario enviado correctamente!",
        });
        // Resetea el formulario o redirige según sea necesario
      } catch (error) {
        Toast({
          tipo: "error",
          mensaje: "¡Hubo un error al enviar el formulario!",
        });
      }
    } else {
      setError(true);
    }
  };

  return (
    <DashboardLayout>
      <div className="min-h-screen flex flex-col items-center">
        <h1 className="text-2xl font-bold mb-4">
          {`Paso ${currentStep} de 7`}
        </h1>

        <form className="w-full max-w-lg" onSubmit={handleSubmit}>
          {/* Paso 1: Autorización de datos */}
          {currentStep === 1 && (
            <div>
              <p>
                Autorizo a Fundación Arduvi para recolectar y usar mis datos
                personales de orden demográfico y localización para fines
                netamente estadísticos y de caracterización, estos datos no
                serán utilizados a nombre propio, ni para un fin diferente al ya
                mencionado. Tengo derecho a conocer, actualizar, rectificar,
                suprimir los datos y revocar la autorización por escrito.
              </p>
              <p className="mt-2 font-bold">
                ¿Autoriza usted el tratamiento de sus datos personales?
              </p>
              <div className="mt-2 flex items-center gap-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="autorizadatos"
                    value="Si"
                    checked={formData.autorizadatos === "Si"}
                    onChange={handleChange}
                  />
                  <span className="ml-2">Sí</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="autorizadatos"
                    value="No"
                    checked={formData.autorizadatos === "No"}
                    onChange={handleChange}
                  />
                  <span className="ml-2">No</span>
                </label>
              </div>
              {error && formData.autorizadatos !== "Si" && (
                <p className="text-red-500 text-sm mt-2">
                  Debes autorizar el tratamiento de datos para continuar.
                </p>
              )}
            </div>
          )}

          {/* Paso 2: Fecha del encuentro */}
          {currentStep === 2 && (
            <div>
              <label className="block mt-4">
                Nombres:
                <input
                  type="text"
                  name="nombres"
                  value={formData.nombres}
                  onChange={(e) => {
                    const re = /^[a-zA-Z\s]*$/; // Solo letras y espacios
                    if (re.test(e.target.value) || e.target.value === "") {
                      handleChange(e); // Solo actualiza el valor si es válido
                    }
                  }}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                />
              </label>
              {error && formData.nombres === "" && (
                <p className="text-red-500 text-sm mt-2">
                  Los Nombres son obligatorios.
                </p>
              )}
              <label className="block mt-4">
                Apellidos:
                <input
                  type="text"
                  name="apellidos"
                  value={formData.apellidos}
                  onChange={(e) => {
                    const re = /^[a-zA-Z\s]*$/; // Solo letras y espacios
                    if (re.test(e.target.value) || e.target.value === "") {
                      handleChange(e); // Solo actualiza el valor si es válido
                    }
                  }}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                />
              </label>
              {error && formData.apellidos === "" && (
                <p className="text-red-500 text-sm mt-2">
                  Los Apellidos son obligatorios.
                </p>
              )}
              <label className="block mt-4">
                Nacionalidad:
                <select
                  name="nacionalidad"
                  value={formData.nacionalidad}
                  onChange={handleChange}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pl-3 pr-8 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                >
                  <option value="">Seleccione una opción</option>
                  <option value="Colombiana">Colombiana</option>
                  <option value="Venezolana">Venezolana</option>
                  <option value="Colombo-venezolana">Colombo-Venezolana</option>
                </select>
              </label>
              {error && formData.nacionalidad === "" && (
                <p className="text-red-500 text-sm mt-2">
                  La Nacionalidad es obligatoria.
                </p>
              )}
              <label className="block mt-4">
                Lugar de origen:
                <input
                  type="text"
                  name="lugar_origen"
                  value={formData.lugar_origen}
                  onChange={handleChange}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                />
              </label>
              {error && formData.lugar_origen === "" && (
                <p className="text-red-500 text-sm mt-2">
                  El lugar de origen es obligatorio.
                </p>
              )}
              <label className="block mt-4">
                Tipo de documento:
                <select
                  name="tipo_documento"
                  value={formData.tipo_documento}
                  onChange={handleChange}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pl-3 pr-8 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                >
                  <option value="">Seleccione una opción</option>
                  <option value="Cédula de ciudadanía">Cédula de ciudadanía</option>
                  <option value="Tarjeta de identidad">Tarjeta de identidad</option>
                  <option value="Cédula de extranjería">Cédula de extranjería</option>
                  <option value="Pasaporte">Pasaporte</option>
                  <option value="PPT">PPT</option>
                  <option value="Cédula de extranjería">Cédula de extranjería</option>
                </select>
              </label>
              {error && formData.tipo_documento === "" && (
                <p className="text-red-500 text-sm mt-2">
                  El tipo de documento es obligatorio.
                </p>
              )}
              <label className="block mt-4">
                Número de documento:
                <input
                  type="text"
                  name="numero_documento"
                  value={formData.numero_documento}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Validar que sea un número positivo y entre 7 y 11 caracteres
                    if (/^\d{0,11}$/.test(value)) {
                      handleChange(e); // Actualizar solo si cumple la longitud
                    }
                  }}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                />
              </label>
              {error && formData.numero_documento === "" && (
                <p className="text-red-500 text-sm mt-2">
                  El número de documento es obligatorio.
                </p>
              )}
              <label className="block mt-4">
                Género:
                <select
                  name="genero"
                  value={formData.genero}
                  onChange={handleChange}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pl-3 pr-8 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                >
                  <option value="">Seleccione una opción</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Femenino">Femenino</option>
                  <option value="Otro">Otro</option>
                </select>
              </label>
              {error && formData.genero === "" && (
                <p className="text-red-500 text-sm mt-2">
                  El género es obligatorio.
                </p>
              )}
              <label className="block mt-4">
                Orientación de identidad:
                <select
                  name="orientacion_identidad"
                  value={formData.orientacion_identidad}
                  onChange={handleChange}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pl-3 pr-8 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                >
                  <option value="">Seleccione una opción</option>
                  <option value="OSIGD">OSIGD</option>
                  <option value="No Aplica">No Aplica</option>
                </select>
              </label>
              {error && formData.orientacion_identidad === "" && (
                <p className="text-red-500 text-sm mt-2">
                  La orientación de identidad es obligatoria.
                </p>
              )}

              <label className="block mt-4">
                Fecha de nacimiento:
                <input
                  type="date"
                  name="fecha_nacimiento"
                  value={formData.fecha_nacimiento}
                  onChange={handleChange}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
              max={new Date().toISOString().split("T")[0]}

                />
              </label>
              {error && formData.fecha_nacimiento === "" && (
                <p className="text-red-500 text-sm mt-2">
                  La fecha de nacimiento es obligatoria.
                </p>
              )}
            <label className="block mt-4">
                Estado civil:
                <select
                  name="estado_civil"
                  value={formData.estado_civil}
                  onChange={handleChange}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pl-3 pr-8 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                >
                  <option value="">Seleccione una opción</option>
                  <option value="Soltero/a">Soltero/a</option>
                  <option value="Casado/a">Casado/a</option>
                  <option value="Unión libre">Unión libre</option>
                  <option value="Separado/a">Separado/a</option>
                  <option value="Divorciado/a">Divorciado/a</option>
                  <option value="Viudo/a">Viudo/a</option>
                </select>
              </label>
              {error && formData.estado_civil === "" && (
                <p className="text-red-500 text-sm mt-2">
                  El estado civil es obligatorio.
                </p>
              )}
              <label className="block mt-4">
                Teléfono:
                <input
                  type="text"
                  name="telefono"
                  value={formData.telefono}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Validar que comience con 3 y que tenga 10 dígitos
                    if (/^3\d{0,9}$/.test(value)) {
                      handleChange(e); // Actualiza el campo solo si cumple con las condiciones
                    }
                  }}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                />
              </label>
              {error && formData.telefono === "" && (
                <p className="text-red-500 text-sm mt-2">
                  El teléfono es obligatorio.
                </p>
              )}
              <label className="block mt-4">
                Correo:
                <input
                  type="email"
                  name="
                  correo"
                  value={formData.correo}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Validar el formato del email
                    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                      handleChange(e); // Actualiza solo si es un formato válido de email
                    } else {
                      handleChange({ target: { name: "email", value: "" } }); // Limpia el campo si no es válido
                    }
                  }}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                />
              </label>
              {error && formData.correo === "" && (
                <p className="text-red-500 text-sm mt-2">
                  El correo es obligatorio.
                </p>
              )}

              <label className="block mt-4">
                Departamento:
                <select
                  name="departamento"
                  value={formData.departamento}
                  onChange={handleChange}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pl-3 pr-8 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                >
                  <option value="">Seleccione una opción</option>
                  <option value="Antioquia">Antioquia</option>
                  <option value="Atlántico">Atlántico</option>
                  <option value="Bogotá D.C.">Bogotá D.C.</option>
                  <option value="Bolívar">Bolívar</option>
                  <option value="Boyacá">Boyacá</option>
                  <option value="Caldas">Caldas</option>
                  <option value="Caquetá">Caquetá</option>
                  <option value="Cauca">Cauca</option>
                  <option value="Cesar">Cesar</option>
                  <option value="Córdoba">Córdoba</option>
                  <option value="Cundinamarca">Cundinamarca</option>
                  <option value="Chocó">Chocó</option>
                  <option value="Huila">Huila</option>
                  <option value="La Guajira">La Guajira</option>
                  <option value="Magdalena">Magdalena</option>
                  <option value="Meta">Meta</option>
                  <option value="Nariño">Nariño</option>
                  <option value="Norte de Santander">Norte de Santander</option>
                  <option value="Quindío">Quindío</option>
                  <option value="Risaralda">Risaralda</option>
                  <option value="Santander">Santander</option>
                  <option value="Sucre">Sucre</option>
                  <option value="Tolima">Tolima
                  </option>
                  <option value="Valle del Cauca">Valle del Cauca</option>
                  <option value="Vaupés">Vaupés</option>
                  <option value="Vichada">Vichada</option>
                </select>
              </label>
              {error && formData.departamento === "" && (
                <p className="text-red-500 text-sm mt-2">
                  El departamento es obligatorio.
                </p>
              )}

              <label className="block mt-4">
                Municipio:
                <input
                  type="text"
                  name="municipio"
                  value={formData.municipio}
                  onChange={handleChange}
                  className="text-sm focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 ease-soft w-full leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:transition-shadow"
                  required
                />
              </label>
              {error && formData.municipio === "" && (
                <p className="text-red-500 text-sm mt-2">
                  El municipio es obligatorio.
                </p>
              )}
              
            </div>
          )}
          <div className="flex justify-between mt-6">
            {currentStep > 1 && (
              <button
                type="button"
                onClick={prevStep}
                className="px-5 py-2 text-sm font-semibold text-white bg-gradient-to-r from-[#00A1FF] to-blue-600 rounded-lg shadow-md hover:scale-105 transform transition ease-in-out duration-150 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00A1FF]"
              >
                Anterior
              </button>
            )}
            {currentStep < 7 ? (
              <button
                type="button"
                onClick={nextStep}
                className="px-5 py-2 text-sm font-semibold text-white bg-gradient-to-r from-[#00A1FF] to-blue-600 rounded-lg shadow-md hover:scale-105 transform transition ease-in-out duration-150 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00A1FF]"
              >
                Siguiente
              </button>
            ) : (
              <button
                type="submit"
                className="px-5 py-2 text-sm font-semibold text-white bg-gradient-to-r from-[#00A1FF] to-blue-600 rounded-lg shadow-md hover:scale-105 transform transition ease-in-out duration-150 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00A1FF]"
              >
                Enviar
              </button>
            )}
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
}

export default CaracterizacionForm;
