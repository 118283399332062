import { Link } from "react-router-dom";
import logoimg from "../../assets/img/fundacion-arduvi.png";
import DashboardLayout from "../../hocs/layout/DashboardLayout";
import { Helmet } from "react-helmet-async";

function Error404() {
return (
    <DashboardLayout>
			<Helmet>
				<title>Página no encontrada</title>
			</Helmet>
      <div className="min-h-screen">
        <div className="flex justify-center">
          <Link to="/" className="mb-2">
            <img src={logoimg} className="w-44" alt="logo" />
          </Link>
        </div>
        <h1 className="text-[10rem] lg:text-[14rem] text-center font-poppins-black text-gray-500">
          404
        </h1>
        <p className="text-sm text-center">
          La página a la que intentas acceder no está disponible
        </p>
        <p className="text-sm text-center">Pulsa en el logo para volver al inicio</p>
      </div>
    </DashboardLayout>
);
}
export default Error404;
