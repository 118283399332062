import { combineReducers } from 'redux';
import auth from './auth.js';
import asunto from './asunto.js';
import asistencia from './asistencia.js';
export default combineReducers({
//  Autenticacion
    auth,
// Asunto
    asunto,
// Asistencia
    asistencia,
})  