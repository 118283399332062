import React from "react";
import SmallSetPagination from "../pagination/SetPagination";
import { Link } from "react-router-dom";
import { IconEye } from "@tabler/icons-react";

function AsistenciaList({
  get_asistencia_list,
  get_asistencia_list_page,
  asistencias,
  count,
}) {

  return (
    <>
      <div className="w-full max-w-full px-3 flex-0 mt-4">
        <div className="relative flex flex-col min-w-0 break-words bg-white border-0 dark:bg-gray-800 rounded-2xl bg-clip-border">
          <div className="overflow-x-auto">
            <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500 dark:border-white/40">
              <thead className="align-bottom">
                <tr>
                  {[
                    "Fecha",
                    "Nombre",
                    "Asunto",
                    "Documento",
                    "Dirección",
                    "Responsable",
                    "Registrado por",
                    "Reportes",
                  ].map((heading) => (
                    <th
                      key={heading}
                      className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 border-solid text-xs tracking-wide whitespace-nowrap text-center text-slate-400 opacity-70 dark:border-white/40 dark:text-white dark:opacity-80"
                    >
                      {heading}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="border-t-2 border-current border-solid dark:border-white/40">
                {asistencias && asistencias.length > 0 ? (
                  asistencias.map((asistencia, index) => (
                    <tr
                      key={index}
                      className="text-gray-700 dark:text-gray-400"
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <span className="font-medium">
                            {asistencia.fechaencuentro}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <span className="font-medium">
                            {asistencia.nombrecompleto}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <span className="font-medium">
                            {asistencia.asunto.name}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <span className="font-medium">
                            {asistencia.tipodocumento}-
                          </span>
                          <span className="font-medium">
                            {asistencia.numeroidentificacion}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <span className="font-medium">
                            {asistencia.direccion}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <span className="font-medium">
                            {asistencia.responsable}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <span className="font-medium">
                            {
                              asistencia.user ? asistencia.user.first_name + " " + asistencia.user.last_name : ""
                            }
                          </span>
                        </div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <Link
                            to={`/asistencia/report/${asistencia.id}`}
                            className="text-blue-500 hover:text-blue-700 dark:text-blue-300 dark:hover:text-blue-500 mr-3"
                          >
                            <IconEye />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="7"
                      className="px-6 py-4 text-center text-gray-500"
                    >
                      No hay registros disponibles
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <SmallSetPagination
        list_page={get_asistencia_list_page}
        list={asistencias}
        count={count}
      />
    </>
  );
}

export default AsistenciaList;
