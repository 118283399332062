import { useState, useEffect } from 'react';

function SetPagination({ list_page, count, type }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [listingsPerPage, setListingsPerPage] = useState(5); // Default to small

    useEffect(() => {
        if (type === 'small') {
            setListingsPerPage(5);
        } else if (type === 'medium') {
            setListingsPerPage(9);
        } else if (type === 'large') {
            setListingsPerPage(18);
        }
    }, [type]);

    const totalPages = Math.ceil(count / listingsPerPage);

    const visitPage = (page) => {
        setCurrentPage(page);
        list_page(page);
    };

    const previous_number = () => {
        if (currentPage > 1) {
            visitPage(currentPage - 1);
        }
    };

    const next_number = () => {
        if (currentPage < totalPages) {
            visitPage(currentPage + 1);
        }
    };

    // const getNumbers = () => {
    //     return (
    //         <div key="current" className="">
    //             <div className={`cursor-pointer border-t-2 pt-4 px-4 inline-flex items-center text-sm font-poppins-medium border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300`}>
    //                 Página {currentPage} de {totalPages}
    //             </div>
    //         </div>
    //     );
    // };

    const getPageNumbers = () => {
        const pageNumbers = [];
        const maxPageNumbers = 3; // Cuántos números de página mostrar al mismo tiempo
        let startPage = Math.max(currentPage - Math.floor(maxPageNumbers / 2), 2); // Empieza en 2, ya que siempre mostramos la primera
        let endPage = Math.min(startPage + maxPageNumbers - 1, totalPages - 1); // Termina antes de la última

        // Ajustar si el rango es menor que maxPageNumbers
        if (endPage - startPage < maxPageNumbers - 1) {
            startPage = Math.max(endPage - maxPageNumbers + 1, 2);
        }

        // Añadir siempre la primera página
        pageNumbers.push(
            <div
                key={1}
                onClick={() => visitPage(1)}
                className={`h-7 w-7 rounded-full flex items-center justify-center mx-1 ${currentPage === 1 ? 'bg-blue-500 font-bold text-white' : 'bg-gray-200 text-black'}`}
            >
                1
            </div>
        );

        // Añadir puntos suspensivos si hay un salto entre la primera página y el rango
        if (startPage > 2) {
            pageNumbers.push(<span key="dots-start" className="px-2">...</span>);
        }

        // Añadir el rango de páginas
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <div
                    key={i}
                    onClick={() => visitPage(i)}
                    className={`h-7 w-7 rounded-full flex items-center justify-center mx-1 ${i === currentPage ? 'bg-blue-500 font-bold text-white' : 'bg-gray-200 text-black'}`}
                >
                    {i}
                </div>
            );
        }

        // Añadir puntos suspensivos si hay un salto entre el rango y la última página
        if (endPage < totalPages - 1) {
            pageNumbers.push(<span key="dots-end" className="px-2">...</span>);
        }

        // Añadir siempre la última página
        if (totalPages > 1) {
            pageNumbers.push(
                <div
                    key={totalPages}
                    onClick={() => visitPage(totalPages)}
                    className={`h-7 w-7 rounded-full flex items-center justify-center mx-1 ${currentPage === totalPages ? 'bg-blue-500 font-bold text-white' : 'bg-gray-200 text-black'}`}
                >
                    {totalPages}
                </div>
            );
        }

        return pageNumbers;
    };

    return (
        <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
            <div className="-mt-px w-0 flex-1 flex">
                <button
                    onClick={previous_number}
                    disabled={currentPage === 1}
                    className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-poppins-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                >
                    Anterior
                </button>
            </div>

            <div className="flex justify-center">
                {getPageNumbers()}
            </div>

            <div className="-mt-px w-0 flex-1 flex justify-end">
                <button
                    onClick={next_number}
                    disabled={currentPage === totalPages}
                    className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-poppins-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                >
                    Siguiente
                </button>
            </div>
        </nav>
    );
}

export default SetPagination;
