import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { get_asistencia_search } from "../../redux/actions/asistencia/asistencia";
import AsistenciaList from "../../components/asistencia/AsistenciaList";
import DashboardLayout from "../../hocs/layout/DashboardLayout";

function AsistenciaSearch({ get_asistencia_search, asistencia }) {
  const params = useParams();
  const term = params.search_term || "";
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState(term);
  useEffect(() => {
    if (term) {
      get_asistencia_search(term);
    }
  }, [get_asistencia_search, term]);
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Actualizamos el término de búsqueda
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchTerm) {
      navigate(`/asistencia/search/${searchTerm}`); // Redirigimos a la página de búsqueda con el término
    }
  };
  return (
    <DashboardLayout>
    <div className="h-screen">
      <h1 className="text-center mt-6 text-2xl font-mono font-bold text-gray-800">
        Listado de visitas
      </h1>
      <div className="flex justify-between items-center mt-6">
        <form onSubmit={handleSearchSubmit} className="flex items-center w-full justify-end space-x-3">
          <div className="relative">
            <input
              id="search"
              name="search"
              value={searchTerm} // Vinculamos el input con el estado
              onChange={handleSearchChange} // Escuchamos los cambios en el input
              className="pl-10 pr-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-fuchsia-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300 dark:placeholder-gray-400"
              placeholder="Buscar"
              type="search"
              required
            />
            <button
              type="submit"
              className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </button>
          </div>
          <Link
            to={"/asistencia/form"}
            className="px-5 py-2 text-sm font-semibold text-white bg-gradient-to-r from-[#00A1FF] to-blue-600 rounded-lg shadow-md hover:scale-105 transform transition ease-in-out duration-150 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00A1FF]"
          >
            Agregar visita
          </Link>
        </form>
      </div>
      <AsistenciaList
        asistencias={asistencia}

      />
    </div>
  </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  asistencia: state.asistencia.filtered_asistencia,
});

export default connect(mapStateToProps, { get_asistencia_search })(AsistenciaSearch);
