import {
  GET_ASISTENCIA_LIST_SUCCESS,
  GET_ASISTENCIA_LIST_FAIL,
  GET_ASISTENCIA_SUCCESS,
  GET_ASISTENCIA_FAIL,
  GET_ASISTENCIA_SEARCH_SUCCESS,
  GET_ASISTENCIA_SEARCH_FAIL
} from "./types"

import axios from "axios"


export const get_asistencia_list = () => async dispatch => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`
    }
  }

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/asistencia/lista/`,
      config
    )

    if (res.status === 200) {
      dispatch({
        type: GET_ASISTENCIA_LIST_SUCCESS,
        payload: res.data
      })
    } else {
      dispatch({
        type: GET_ASISTENCIA_LIST_FAIL
      })
    }
  } catch {
    dispatch({
      type: GET_ASISTENCIA_LIST_FAIL
    })
  }
}

export const get_asistencia_list_page = (p) => async dispatch => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`
    }
  }

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/asistencia/lista/?p=${p}`,
      config
    )

    if (res.status === 200) {
      dispatch({
        type: GET_ASISTENCIA_LIST_SUCCESS,
        payload: res.data
      })
    } else {
      dispatch({
        type: GET_ASISTENCIA_LIST_FAIL
      })
    }
  } catch {
    dispatch({
      type: GET_ASISTENCIA_LIST_FAIL
    })
  }
}

export const get_asistencia = (id) => async dispatch => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`
    }
  }

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/asistencia/detalle/${id}/`,
      config
    )

    if (res.status === 200) {
      dispatch({
        type: GET_ASISTENCIA_SUCCESS,
        payload: res.data
      })
    } else {
      dispatch({
        type: GET_ASISTENCIA_FAIL
      })
    }
  } catch {
    dispatch({
      type: GET_ASISTENCIA_FAIL
    })
  }
}

export const get_asistencia_search = query => async dispatch => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`
    }
  }

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/asistencia/filtrado/${query}`,
      config
    )

    if (res.status === 200) {
      dispatch({
        type: GET_ASISTENCIA_SEARCH_SUCCESS,
        payload: res.data
      })
    } else {
      dispatch({
        type: GET_ASISTENCIA_SEARCH_FAIL
      })
    }
  } catch {
    dispatch({
      type: GET_ASISTENCIA_SEARCH_FAIL
    })
  }
}

