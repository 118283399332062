import {
  GET_ASUNTO_LIST_SUCCESS,
  GET_ASUNTO_LIST_FAIL,
  GET_ASUNTO_SUCCESS,
  GET_ASUNTO_FAIL,
  GET_ASUNTO_SEARCH_SUCCESS,
  GET_ASUNTO_SEARCH_FAIL
} from "./types"

import axios from "axios"


export const get_asunto_list = () => async dispatch => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`
    }
  }

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/asunto/lista/`,
      config
    )

    if (res.status === 200) {
      dispatch({
        type: GET_ASUNTO_LIST_SUCCESS,
        payload: res.data
      })
    } else {
      dispatch({
        type: GET_ASUNTO_LIST_FAIL
      })
    }
  } catch {
    dispatch({
      type: GET_ASUNTO_LIST_FAIL
    })
  }
}

export const get_asunto_list_page = p => async dispatch => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`
    }
  }

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/asunto/lista/?p=${p}`,
      config
    )

    if (res.status === 200) {
      dispatch({
        type: GET_ASUNTO_LIST_SUCCESS,
        payload: res.data
      })
    } else {
      dispatch({
        type: GET_ASUNTO_LIST_FAIL
      })
    }
  } catch {
    dispatch({
      type: GET_ASUNTO_LIST_FAIL
    })
  }
}

export const get_asunto = id => async dispatch => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`
    }
  }

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/asunto/detalle/${id}/`,
      config
    )

    if (res.status === 200) {
      dispatch({
        type: GET_ASUNTO_SUCCESS,
        payload: res.data
      })
    } else {
      dispatch({
        type: GET_ASUNTO_FAIL
      })
    }
  } catch {
    dispatch({
      type: GET_ASUNTO_FAIL
    })
  }
}

export const get_asunto_search = query => async dispatch => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`
    }
  }

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/asunto/search/${query}`,
      config
    )

    if (res.status === 200) {
      dispatch({
        type: GET_ASUNTO_SEARCH_SUCCESS,
        payload: res.data
      })
    } else {
      dispatch({
        type: GET_ASUNTO_SEARCH_FAIL
      })
    }
  } catch {
    dispatch({
      type: GET_ASUNTO_SEARCH_FAIL
    })
  }
}

