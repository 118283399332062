import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { load_user, logout, refresh } from "../../redux/actions/auth/auth";
import useDarkMode from "../../hooks/useDarkMode";
import logo from "../../assets/img/logo.png";
import { Close, DarkMode, LightMode, LogoutOutlined } from "@mui/icons-material";
import Footer from "../../components/navigation/Footer";
import Sidebar from "../../components/navigation/Sidebar";
import Swal from "sweetalert2";

const DashboardLayout = ({ children, refresh, load_user, logout }) => {
  const user = useSelector((state) => state.auth.user);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [darkTheme, setDarkTheme] = useDarkMode();
  const [selectedItem, setSelectedItem] = useState(localStorage.getItem("selectedItem") || "Inicio");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    refresh();
    load_user();
  }, [load_user, refresh]);

  useEffect(() => {
    const pathToItem = {
      "/dashboard": "Dashboard",
      "/asistencia": "Asistencia",
      "/asuntos": "Asuntos",

    };
    const currentItem = pathToItem[location.pathname];
    if (currentItem) {
      setSelectedItem(currentItem);
      localStorage.setItem("selectedItem", currentItem);
    }
  }, [location]);

  const handleLogout = () => {
    Swal.fire({
      title: '¿Está seguro?',
      text: "¿Desea cerrar sesión?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00A1FF',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, cerrar sesión',
      cancelButtonText: 'No, cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
        navigate("/");
        window.location.reload();
      }
    });
  };

  const handleSidebarClick = (item) => {
    setSelectedItem(item);
    setSidebarOpen(false);
    localStorage.setItem("selectedItem", item);
  };

  const breadcrumbNameMap = {
    "/dashboard": "Dashboard",
    "/asistencia": "Asistencia",
    "/asuntos": "Asuntos",
  };
  
  const pathSnippets = location.pathname.split("/").filter(i => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return (
      <li key={url} className="text-sm pl-2 capitalize leading-normal  dark:text-white before:float-left before:pr-2 before:text-gray-600 dark:before:text-gray-400 before:content-['/']" aria-current="page">
        {breadcrumbNameMap[url]}
      </li>
    );
  });

  return (
    <div className={`font-poppins-regular m-0 font-sans text-base antialiased font-normal leading-default min-h-screen ${darkTheme ? "bg-gray-900 text-slate-300" : "bg-[#ffffff] text-slate-500"}`}>
      <aside className={`max-w-62.5 ease-nav-brand z-40 fixed inset-y-0 my-4 ml-4 block w-full -translate-x-full flex-wrap items-center justify-between overflow-y-auto rounded-2xl border-0 ${darkTheme ? "bg-gray-800 text-white" : "bg-slate-200 text-slate-700"} p-0 antialiased shadow-none transition-transform duration-200 xl:left-0 xl:translate-x-0 ${sidebarOpen ? "translate-x-0" : "-translate-x-full"}`}>
        <div className="h-19.5 flex justify-between items-center px-4">
          <Link className="w-full text-sm whitespace-nowrap" to={"/dashboard"} onClick={() => handleSidebarClick("Dashboard")}>
            <img src={logo} className="inline h-full max-w-full transition-all duration-200 ease-nav-brand max-h-8" alt="main_logo" />
            <span className="ml-1 font-poppins-semibold transition-all duration-200 ease-nav-brand">Fundacion Arduvi App</span>
          </Link>
          <div className="cursor-pointer flex lg:hidden" onClick={() => setSidebarOpen(false)}>
            <Close />
          </div>
        </div>
        <hr className="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent" />
        <div className="items-center block w-auto overflow-auto grow basis-full">
          <Sidebar
            selectedItem={selectedItem}
            handleSidebarClick={handleSidebarClick}
            darkTheme={darkTheme}
          />
          <hr className="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent" />
        </div>
      </aside>
      <main className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200 overflow-y-auto">
        <nav className="relative flex flex-wrap items-center justify-between px-0 py-2 mx-6 transition-all shadow-none duration-250 ease-soft-in rounded-2xl lg:flex-nowrap lg:justify-start" navbar-main="true" navbar-scroll="true">
          <div className="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
            <nav>
              <ol className="flex flex-wrap pt-1 mr-12 bg-transparent rounded-lg sm:mr-16">
                <li className="text-sm leading-normal">
                  <Link className="opacity-50 text-slate-700 dark:text-white" to="#">
                    Página
                  </Link>
                </li>
                {extraBreadcrumbItems}
              </ol>
              <h6 className="mb-0 font-poppins-bold capitalize text-slate-700 dark:text-white">
                {breadcrumbNameMap[location.pathname]}
              </h6>
            </nav>
            <div className="flex items-center justify-end mt-2 grow sm:mt-0 sm:mr-6 md:mr-0 lg:flex lg:basis-auto">
              <ul className="flex flex-row justify-end pl-0 mb-0 list-none md-max:w-full gap-x-4">
                <li>
                  <button className="text-slate-500 flex items-center gap-x-1" onClick={handleLogout}>
                    <LogoutOutlined />
                    <span className="text-sm">Salir</span>
                  </button>
                </li>
                <li className="flex items-center pl-4 xl:hidden">
                  <button className="block p-0 text-sm transition-all ease-nav-brand text-slate-500" onClick={() => setSidebarOpen(!sidebarOpen)}>
                    <div className="w-4.5 overflow-hidden">
                      <i className="ease-soft mb-0.75 relative block h-0.5 rounded-sm bg-slate-500 transition-all"></i>
                      <i className="ease-soft mb-0.75 relative block h-0.5 rounded-sm bg-slate-500 transition-all"></i>
                      <i className="ease-soft relative block h-0.5 rounded-sm bg-slate-500 transition-all"></i>
                    </div>
                  </button>
                </li>
                <li className="">
                  <button className="text-slate-500" onClick={() => setDarkTheme((prev) => !prev)}>
                    {darkTheme ? <LightMode /> : <DarkMode />}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="w-full px-6 mx-auto">
          {children}
          <Footer />
        </div>
      </main>

    </div>
  );
};

const mapStateToProps = (state) => ({
  user_loading: state.auth.user_loading,
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  refresh,
  load_user,
  logout,
})(DashboardLayout);
