import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashboardLayout from "../../hocs/layout/DashboardLayout";
import AsistenciaList from "../../components/asistencia/AsistenciaList";
import { get_asistencia_list,get_asistencia_list_page } from "../../redux/actions/asistencia/asistencia";
import { connect } from "react-redux";
function Asistencia({ get_asistencia_list, get_asistencia_list_page, asistencias, count }) {
  const [term , setTerm] = useState("");


  const handleChange = (e) => {
    setTerm(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (term) {
      setTimeout(() => (window.location.href = `/asistencia/search/${term}`), 0.2);
      setTerm(""); // Limpiar el campo de búsqueda después de la redirección
    }
  };
  useEffect(() => {
    get_asistencia_list();
  }, [get_asistencia_list]);
  return (
    <DashboardLayout>
      <div className="h-screen">
        <h1 className="text-center mt-6 text-2xl font-mono font-bold text-gray-800">
          Listado de visitas
        </h1>
        <div className="flex justify-between items-center mt-6">
          <form onSubmit={onSubmit}  className="flex items-center w-full justify-end space-x-3">
            <div className="relative">
              <input
                id="search"
                name="search"
                value={term}  // Vinculamos el valor del input con el estado 'term'
                onChange={handleChange}  // Escuchamos los cambios en el input
                className="pl-10 pr-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-fuchsia-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300 dark:placeholder-gray-400"
                placeholder="Buscar"
                type="search"
                required
              />
              <button
                type="submit"
                className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </button>
            </div>
            <Link
              to={"/asistencia/form"}
              className="px-5 py-2 text-sm font-semibold text-white bg-gradient-to-r from-[#00A1FF] to-blue-600 rounded-lg shadow-md hover:scale-105 transform transition ease-in-out duration-150 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00A1FF]"
            >
              Agregar visita
            </Link>
          </form>
        </div>
        <AsistenciaList 
        get_asistencia_list={get_asistencia_list}
        get_asistencia_list_page={get_asistencia_list_page}
        asistencias={asistencias}
        count={count}
        />
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  asistencias: state.asistencia.asistencia_list,
  count: state.asistencia.count,
});

export default connect(mapStateToProps, { get_asistencia_list, get_asistencia_list_page })(Asistencia);
