import {
  GET_ASISTENCIA_LIST_SUCCESS,
  GET_ASISTENCIA_LIST_FAIL,
  GET_ASISTENCIA_SUCCESS,
  GET_ASISTENCIA_FAIL,
  GET_ASISTENCIA_SEARCH_SUCCESS,
  GET_ASISTENCIA_SEARCH_FAIL,
} from "../actions/asistencia/types";

const initialState = {
  asistencia_list: [],
  asistencia: null,
  filtered_asistencia: null,
  count: null,
  next: null,
  previous: null,
};


export default function asistencia(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ASISTENCIA_LIST_SUCCESS:
      return {
        ...state,
        asistencia_list: payload.results,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_ASISTENCIA_LIST_FAIL:
      return {
        ...state,
        asistencia_list: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_ASISTENCIA_SUCCESS:
      return {
        ...state,
        asistencia: payload.asistencia,
      };
    case GET_ASISTENCIA_FAIL:
      return {
        ...state,
        asistencia: null,
      };
    case GET_ASISTENCIA_SEARCH_SUCCESS:
      return {
        ...state,
        filtered_asistencia: payload.asistencias_filtradas,
      };
    case GET_ASISTENCIA_SEARCH_FAIL:
      return {
        ...state,
        filtered_asistencia: null,
      };
    default:
      return state;
  }
}

