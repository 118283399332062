import React from "react";
import { Link } from "react-router-dom";
import {
  IconBrandInstagram,
  IconBrandX,
  IconBrandFacebook,
  IconBrandLinkedin,
  IconBrandYoutube
} from "@tabler/icons-react";
import logo from "../../assets/img/fundacion-arduvi.png";

function Footer() {
  return (
    <footer className="py-12 bg-white dark:bg-[#333844]">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between">
        <div className="w-full lg:w-1/3 flex justify-center lg:justify-start mb-4 lg:mb-0">
          <img src={logo} alt="logo" className="w-32 h-auto" />
        </div>
        <div className="w-full lg:w-1/3 text-center">
          <div className="flex justify-center gap-x-6 mb-4">
            <Link
              to="https://fundacionarduvi.org"
              className="text-slate-400 dark:text-gray-400"
            >
              Inicio
            </Link>
            <Link
              to="https://fundacionarduvi.org/nosotros"
              className="text-slate-400 dark:text-gray-400"
            >
              Nostros
            </Link>
            <Link
              to="https://fundacionarduvi.org/servicios"
              className="text-slate-400 dark:text-gray-400"
            >
              Servicios
            </Link>
            <Link
              to="https://fundacionarduvi.org/voluntariado"
              className="text-slate-400 dark:text-gray-400"
            >
              Voluntariado
            </Link>
            <Link
              to="https://fundacionarduvi.org/blog"
              className="text-slate-400 dark:text-gray-400"
            >
              Blog
            </Link>
          </div>
          <div className="flex justify-center gap-x-4 mb-4">
            <Link
              to="https://www.instagram.com/fundacionarduvi/"
              className="text-slate-400 dark:text-gray-400"
            >
              <IconBrandInstagram size={24} />
            </Link>
            <Link
              to="https://www.facebook.com/Fundacion-Arduvi-103873258896429"
              className="text-slate-400 dark:text-gray-400"
            >
              <IconBrandFacebook size={24} />
            </Link>
            <Link
              to="https://x.com/arduvifundacion?s=21&t=Ff1wK_WTR7wsA-xB_OgMsg"
              className="text-slate-400 dark:text-gray-400"
            >
              <IconBrandX size={24} />
            </Link>
            <Link
              to="https://www.youtube.com/@fundacionarduvi2655"
              className="text-slate-400 dark:text-gray-400"
            >
              <IconBrandYoutube size={24} />
            </Link>
          </div>
          <p className="text-slate-400 dark:text-gray-400">
            Copyright © {new Date().getFullYear()} Fundacion Arduvi.
          </p>
        </div>
        <div className="w-full lg:w-1/3"></div>
      </div>
    </footer>
  );
}

export default Footer;
