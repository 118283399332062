import React, { useState, useEffect } from "react";
import axios from "axios";
import Toast from "../../components/Alert";

function AsuntoModal({ show, onClose, asuntoData, refreshAsuntos }) {
  const [formData, setFormData] = useState({
    name: "",
    active: true,
  });
  const [statusMessage, setStatusMessage] = useState(null);

  useEffect(() => {
    if (asuntoData) {
      setFormData({
        name: asuntoData.name,
        active: asuntoData.active,
      });
    } else {
      setFormData({
        name: "",
        active: true,
      });
    }
  }, [asuntoData, show]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    try {
      let response;
      if (asuntoData) {
        response = await axios.put(
          `${process.env.REACT_APP_API_URL}/asunto/actualizar/${asuntoData.id}/`,
          formData,
          config
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/asunto/lista/`,
          formData,
          config
        );
      }

      if (response.status === 201 || response.status === 200) {
        Toast({
          tipo: "success",
          mensaje: asuntoData ? "¡Cambios guardados correctamente!" : "¡Asunto registrado correctamente!",
        });
      }

      refreshAsuntos();
      onClose();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Verifica si el mensaje de error contiene "Los campos name deben formar un conjunto único"
        const errors = error.response.data.non_field_errors || [];
        if (errors.length > 0 && errors[0].includes("Los campos name deben formar un conjunto único")) {
          Toast({
            tipo: "error",
            mensaje: "¡El nombre del asunto ya está en uso!",
          });
        } else {
          Toast({
            tipo: "error",
            mensaje: "¡Hubo un error al registrar el asunto!",
          });
        }
      } else {
        Toast({
          tipo: "error",
          mensaje: "¡Hubo un error al registrar el asunto!",
        });
      }
    }
  };

  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black/50 backdrop-blur-sm">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-md sm:w-2/3 md:w-1/3 p-4 md:p-6">
        <div className="border-b border-gray-200 dark:border-gray-700 pb-2">
          <h2 className="text-xl font-semibold text-gray-800 dark:text-white">
            {asuntoData ? "Editar Asunto" : "Crear Asunto"}
          </h2>
        </div>
        <form onSubmit={handleSubmit} className="py-4">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Nombre del Asunto
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="input input-bordered input-md w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Activo
            </label>
            <select
              name="active"
              value={formData.active}
              onChange={handleChange}
              className="select select-bordered w-full"
            >
              <option value={true}>Sí</option>
              <option value={false}>No</option>
            </select>
          </div>
          {statusMessage && (
            <div className="mb-4 text-center text-red-500">
              {statusMessage}
            </div>
          )}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg mr-2 hover:bg-gray-300 dark:bg-gray-600 dark:text-gray-300 dark:hover:bg-gray-700"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="px-5 py-2 text-sm font-semibold text-white bg-gradient-to-r from-[#00A1FF] to-blue-600 rounded-lg shadow-md hover:scale-105 transform transition ease-in-out duration-150 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00A1FF]"
            >
              {asuntoData ? "Guardar Cambios" : "Crear Asunto"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AsuntoModal;
