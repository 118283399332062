import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import DashboardLayout from "../../hocs/layout/DashboardLayout";
import AsuntoList from "../../components/asunto/AsuntoList";
import AsuntoModal from "./Modal";
import { get_asunto_search } from "../../redux/actions/asunto/asunto";
import { connect } from "react-redux";

function Asunto({ get_asunto_search, search_asunto }) {
  const params = useParams();
  const navigate = useNavigate();
  const initialSearchTerm = params.search_term || ""; // Inicializar con el término de búsqueda actual en la URL
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

  useEffect(() => {
    if (searchTerm) {
      get_asunto_search(searchTerm);
    }
  }, [get_asunto_search, searchTerm]);

  const [showModal, setShowModal] = useState(false);
  const [currentAsunto, setCurrentAsunto] = useState(null);

  const handleCreate = () => {
    setCurrentAsunto(null);
    setShowModal(true);
  };

  const handleEdit = (asunto) => {
    setCurrentAsunto(asunto);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setCurrentAsunto(null);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchTerm) {
      navigate(`/asuntos/search/${searchTerm}`); // Actualizar la URL con el nuevo término de búsqueda
      get_asunto_search(searchTerm); // Realizar la búsqueda
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Actualizar el estado del término de búsqueda
  };

  return (
    <DashboardLayout>
      <div className="h-screen">
        <h1 className="text-center mt-6 text-2xl font-mono font-bold text-gray-800">
          Listado de Asunto
        </h1>
        <div className="flex justify-between items-center mt-6">
          <form onSubmit={handleSearchSubmit} className="flex items-center w-full justify-end space-x-3">
            <div className="relative">
              <input
                id="search"
                name="search"
                value={searchTerm} // Vincular el estado del término de búsqueda
                onChange={handleSearchChange} // Manejar los cambios en el campo de búsqueda
                className="pl-10 pr-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-fuchsia-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300 dark:placeholder-gray-400"
                placeholder="Buscar"
                type="search"
              />
              <button
                type="submit"
                className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </button>
            </div>
            <button
              onClick={handleCreate}
              type="button"
              className="px-5 py-2 text-sm font-semibold text-white bg-gradient-to-r from-[#00A1FF] to-blue-600 rounded-lg shadow-md hover:scale-105 transform transition ease-in-out duration-150 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00A1FF]"
            >
              Agregar asunto
            </button>
          </form>
        </div>
        <AsuntoList
          asuntos={search_asunto}
          onEdit={handleEdit}
        />
        <AsuntoModal
          show={showModal}
          onClose={closeModal}
          asuntoData={currentAsunto}
          refreshAsuntos={() => get_asunto_search(searchTerm)}
        />
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  search_asunto: state.asunto.filtered_asunto,
});

export default connect(mapStateToProps, { get_asunto_search })(Asunto);
