import React from "react";
import SmallSetPagination from "../pagination/SetPagination";
import AsuntoCardHorizontal from "./AsuntoCardHorizontal";

function AsuntoList({ get_asunto_list_page, asuntos, count, onEdit }) {
  return (
    <>
      <div className="w-full max-w-full px-3 flex-0 mt-4">
        <div className="relative flex flex-col min-w-0 break-words bg-white border-0 dark:bg-gray-800 rounded-2xl bg-clip-border">
          <div className="overflow-x-auto">
            <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500 dark:border-white/40">
              <thead className="align-bottom">
                <tr>
                  {[
                    "Descripción",
                    "Estado",
                    "Fecha de Creacion",
                    "Creado por",
                    "Acciones",
                  ].map((heading) => (
                    <th
                      key={heading}
                      className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 border-solid text-xs tracking-wide whitespace-nowrap text-center text-slate-400 opacity-70 dark:border-white/40 dark:text-white dark:opacity-80"
                    >
                      {heading}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="border-t-2 border-current border-solid dark:border-white/40">
                {asuntos && asuntos.length > 0 ? (
                  asuntos.map((asunto, index) => (
                    <AsuntoCardHorizontal
                      key={index}
                      data={asunto}
                      onEdit={() => onEdit(asunto)}
   
                    />
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="7"
                      className="px-6 py-4 text-center text-gray-500"
                    >
                      No hay registros disponibles
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <SmallSetPagination
        list_page={get_asunto_list_page}
        list={asuntos}
        count={count}
      />
    </>
  );
}

export default AsuntoList;
