import {
  GET_ASUNTO_LIST_SUCCESS,
  GET_ASUNTO_LIST_FAIL,
  GET_ASUNTO_SUCCESS,
  GET_ASUNTO_FAIL,
  GET_ASUNTO_SEARCH_SUCCESS,
  GET_ASUNTO_SEARCH_FAIL,
} from "../actions/asunto/types";

const initialState = {
  asunto_list: [],
  asunto: null,
  filtered_asunto: null,
  count: null,
  next: null,
  previous: null,
};


export default function asunto(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ASUNTO_LIST_SUCCESS:
      return {
        ...state,
        asunto_list: payload.results,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_ASUNTO_LIST_FAIL:
      return {
        ...state,
        asunto_list: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_ASUNTO_SUCCESS:
      return {
        ...state,
        asunto: payload.asunto,
      };
    case GET_ASUNTO_FAIL:
      return {
        ...state,
        asunto: null,
      };
    case GET_ASUNTO_SEARCH_SUCCESS:
      return {
        ...state,
        filtered_asunto: payload.asuntos_filtrados,
      };
    case GET_ASUNTO_SEARCH_FAIL:
      return {
        ...state,
        filtered_asunto: null,
      };
    default:
      return state;
  }
}

