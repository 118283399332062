import { useParams } from "react-router-dom";
import { get_asistencia } from "../../redux/actions/asistencia/asistencia";
import { connect, useSelector } from "react-redux";
import { useEffect } from "react";
import DashboardLayout from "../../hocs/layout/DashboardLayout";
import { PDFViewer } from "@react-pdf/renderer";
import MyDocument from "../../components/asistencia/ReportAsistencia";  
function AsistenciaReport({ get_asistencia, asistencia }) {
  const params = useParams();
  const  id  = params.uid;
  useEffect(() => {
    get_asistencia(id);
  }, [get_asistencia, id]);

  console.log(asistencia);
  return (
   <DashboardLayout>
      <div className="border-b border-gray-200 py-5 sm:px-6">
        <div className="flex flex-col flex-wrap lg:flex-row lg:items-center justify-between sm:flex-nowrap">
          <div className="">
            <h3 className="text-2xl font-medium leading-6 text-gray-900 dark:text-white">
              Reporte de Asistencia.
            </h3>
          </div>
        </div>
        <div className="mt-10 flex flex-col lg:flex-row lg:items-center gap-4 py-2 lg:py-0">
          {asistencia ? (
            <div className="w-full">
              <PDFViewer className="w-full h-[75vh] md:h-[85vh]">
                <MyDocument data={asistencia} />
              </PDFViewer>
            </div>
          ) : (
            <div className="flex items-center justify-center h-96">
              <div className="w-10 h-10 border-t-2 border-b-2 border-[#0096C4] rounded-full animate-spin"></div>
            </div>
          )}
        </div>
      </div>
   </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  asistencia: state.asistencia.asistencia,
});

export default connect(mapStateToProps, { get_asistencia })(AsistenciaReport);
