import React from "react";
import {
  Document,
  Font,
  Page,
  Text,
  StyleSheet,
  View,
  Image,
} from "@react-pdf/renderer";
import logo from "../../assets/img/logo-full.png";

// Registrar la fuente
Font.register({
  family: "sans-serif",
});

// Estilos para el PDF
const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 20,
    textAlign: "center",
    marginBottom: 20,
  },
  text: {
    fontSize: 12,
    textAlign: "justify",
    marginBottom: 10,
  },
  sectionTitle: {
    fontSize: 14,
    marginBottom: 8,
    color: "#333333",
  },
  contentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 8,
  },
  contentKey: {
    fontSize: 12,
    color: "#333333",
  },
  contentValue: {
    fontSize: 12,
    color: "#555555",
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  wrapper: {
    border: "1px solid #dddddd",
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: "auto",
    width: 100,
  },
  signatureImage: {
    marginVertical: 15,
    width: 150,
    height: 50,
  },
});

// Función para formatear fechas
const formatDateTime = (date) => {
  if (!date) return "Fecha no disponible";
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Intl.DateTimeFormat("es-ES", options).format(new Date(date));
};

// Componente principal para generar el documento PDF
function MyDocument({ data }) {
  const currentDate = new Date();
  const formattedDate = formatDateTime(currentDate);

  // Construir la URL completa de la firma
  const firmaUrl = `${process.env.REACT_APP_API_URL}${data.firma}`;

  return (
    <>
      {data ? (
        <Document title={`Reporte de Asistencia - ${data.nombrecompleto}`}>
          <Page style={styles.body}>
            {/* Título principal */}
            <Image style={styles.image} src={logo} />
            <Text style={styles.title}>Reporte de Información</Text>

            {/* Sección de detalles del asunto */}
            <View style={styles.wrapper}>
              <Text style={styles.sectionTitle}>Datos Personales</Text>
              <View style={styles.contentGroup}>
                <View style={styles.contentRow}>
                  <View>
                    <Text style={styles.contentKey}>Nombre Completo:</Text>
                    <Text style={styles.contentValue}>{data.nombrecompleto}</Text>
                  </View>
                  <View>
                    <Text style={styles.contentKey}>Número de Identificación:</Text>
                    <Text style={styles.contentValue}>{data.tipodocumento}-{data.numeroidentificacion}</Text>
                  </View>
                  <View>
                    <Text style={styles.contentKey}>Edad:</Text>
                    <Text style={styles.contentValue}>{data.edad}</Text>
                  </View>
                </View>
                <View style={styles.contentRow}>
                  <View>
                    <Text style={styles.contentKey}>Género:</Text>
                    <Text style={styles.contentValue}>{data.genero}</Text>
                  </View>
                </View>
              </View>
            </View>

            {/* Sección de detalles adicionales */}
            <View style={styles.wrapper}>
              <Text style={styles.sectionTitle}>Datos de Contacto</Text>
              <View style={styles.contentGroup}>
                <View style={styles.contentRow}>
                  <View>
                    <Text style={styles.contentKey}>Teléfonos:</Text>
                    <Text style={styles.contentValue}>{data.telefono1} / {data.telefono2}</Text>
                  </View>
                </View>
                <View style={styles.contentRow}>
                  <View>
                    <Text style={styles.contentKey}>Municipio:</Text>
                    <Text style={styles.contentValue}>{data.municipio}</Text>
                  </View>
                  <View>
                    <Text style={styles.contentKey}>Barrio:</Text>
                    <Text style={styles.contentValue}>{data.barrio}</Text>
                  </View>
                  <View>
                    <Text style={styles.contentKey}>Zona:</Text>
                    <Text style={styles.contentValue}>{data.zona}</Text>
                  </View>
                </View>
                <View style={styles.contentRow}>
                  <View>
                    <Text style={styles.contentKey}>Dirección:</Text>
                    <Text style={styles.contentValue}>{data.direccion}</Text>
                  </View>
                </View>
              </View>
            </View>

            {/* Sección de datos socioeconómicos */}
            <View style={styles.wrapper}>
              <Text style={styles.sectionTitle}>Datos Socioeconómicos</Text>
              <View style={styles.contentGroup}>
                <View style={styles.contentRow}>
                  <View>
                    <Text style={styles.contentKey}>Escolaridad:</Text>
                    <Text style={styles.contentValue}>{data.escolaridad}</Text>
                  </View>
                  <View>
                    <Text style={styles.contentKey}>Grupo Poblacional:</Text>
                    <Text style={styles.contentValue}>{data.grupopoblacional}</Text>
                  </View>
                  <View>
                    <Text style={styles.contentKey}>Grupo Étnico:</Text>
                    <Text style={styles.contentValue}>{data.grupoetnico}</Text>
                  </View>
                </View>
                <View style={styles.contentRow}>
                  <View>
                    <Text style={styles.contentKey}>Régimen de Salud:</Text>
                    <Text style={styles.contentValue}>{data.regimensalud}</Text>
                  </View>
                </View>
              </View>
            </View>

            {/* Sección del responsable */}
            <View style={styles.wrapper}>
              <Text style={styles.sectionTitle}>Responsable</Text>
              <View style={styles.contentGroup}>
                <View style={styles.contentRow}>
                  <View>
                    <Text style={styles.contentKey}>Responsable:</Text>
                    <Text style={styles.contentValue}>{data.responsable}</Text>
                  </View>
                  <View>
                    <Text style={styles.contentKey}>Correo Electrónico:</Text>
                    <Text style={styles.contentValue}>{data.user.email}</Text>
                  </View>
                </View>
              </View>
            </View>

            {/* Sección de la firma */}
            {data.firma && (
              <View>
                <Text style={styles.contentKey}>Firma</Text>
                <Image style={styles.signatureImage} src={firmaUrl} />
              </View>
            )}

            {/* Pie de página */}
            <Text style={styles.footer}>
              Reporte generado el {formattedDate}
            </Text>
          </Page>
        </Document>
      ) : (
        <Text>No se pudieron obtener los datos</Text>
      )}
    </>
  );
}

export default MyDocument;
